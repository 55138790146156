<template>
    <div :class="rootClasses">
        <div class="container">
            <div class="main-title">{{ data.title }}</div>
            <div class="inner">
                <div class=" row line" v-for="line, key in data.textList" :key="key" :class="{ gray: key % 2 == 0 }">
                    <div class="col-12 col-md-1 line-number">{{ line.number }}</div>
                    <div class="col-12 col-md-11 line-text">
                        <span v-html="line.text"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextWithNumber',
    components: {},
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {}
    },
    computed: {
        rootClasses() {
            let result = {
                ['cs-text-with-number']: true,
                ['theme-block']: true
            };

            if (this.data?.hasBackground) result = {
                ...result,
                ...{
                    ['has-background']: true,
                    ['theme-bg-colored']: true
                }
            }

            return result;
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../styles/main.scss';

.main-title {
    @include title;
    text-align: center;
    margin-bottom: $block_space_sm;
}

.gray {
    background-color: #f7f7f7;
}

.inner {
    .line {
        margin: 5px 0;
        padding: 14px 30px;

        @media (max-width:500px) {
            padding: 14px 0px;
        }

        &-number {
            font-weight: $theme_bold_text;
            font-size: 36px;
            color: $theme_color;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-text {
            display: flex;
            align-items: center;

            ::v-deep {
                * {
                    color: #000;
                }
            }
        }
    }
}
</style>
